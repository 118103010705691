import React from "react"
import { render } from "react-dom"
import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { rootReducer } from "./redux/rootReducer"
import "./index.css"
import App from "./App"
import "typeface-gudea"
import { BrowserRouter as Router } from "react-router-dom"
import { Provider } from "react-redux"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"


const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
)

render(
  <Provider store={store}>
      <Router>
        <App />
      </Router>
  </Provider>,
  document.getElementById("root")
)
