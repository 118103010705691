import React, { useState, useEffect } from "react"
import Footer from "./components/Footer"
import Header from "./components/Header"
import Content from "./components/Content"
import { Switch, Route } from "react-router-dom"
import { useDispatch } from 'react-redux'
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
  responsiveFontSizes,
  useTheme,
} from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import {Paper} from "@material-ui/core"
import { globalWidth } from './redux/actions'

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
  },
  AppPaper: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    "& > header": {
      background: "#ffffff !important",
      color: "#333333",
    },
  },
}))

function useWidth() {
  const theme = useTheme()
  const keys = [...theme.breakpoints.keys].reverse()
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key))
      return !output && matches ? key : output
    }, null) || "xs"
  )
}


const App = () => {
  const detect = window.matchMedia('(prefers-color-scheme: dark)').matches
  const [darkMode, setDarkMode] = useState(false)
  const dispatch = useDispatch()
  const width = useWidth()
  console.log(detect)
  useEffect(() => {
    dispatch(globalWidth(width))
  }, [dispatch, width])

  useEffect(() => {
    detect && setDarkMode(true)
  },[detect])
  let theme = createMuiTheme({

    palette: {
      type: darkMode ? "dark" : "light",
      primary: {
        main: "#cc0000",
        light: "d63333",
        dark: "#8e0000",
        contrastText: "#fff",
      },
      secondary: {
        main: "#3d5afe",
        light: "637bfe",
        dark: "#2a3eb1",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(
        ","
      ),
      useNextVariants: true,
      htmlFontSize: 18,
    },
  })
  
  theme = responsiveFontSizes(theme)
  const classes = useStyles()

  return (
    <div className="App" style={{ height: "100vh" }}>
      <ThemeProvider theme={theme}>
        <Paper className={classes.AppPaper} square elevation={0}>
          <Header setDarkMode={setDarkMode} darkMode={darkMode} />
          <Content />
          <Footer darkMode={darkMode} />
          <Switch>
            <Route path="/" exact />
          </Switch>
        </Paper>
      </ThemeProvider>
    </div>
  )
}

export default App
