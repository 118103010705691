import React from "react"
import {
  Link,
  Grid,
  Container,
  Typography,
  CssBaseline,
  makeStyles,
  Paper
} from "@material-ui/core"
import CopyrightIcon from "@material-ui/icons/Copyright"
import SocialMediaIcons from "./SocialMediaIcons"
import { useSelector } from "react-redux"

const useStyles = makeStyles((theme) => ({
  PaperRoot: {
    width: '100%',
    minHeight: '4rem',
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  root: {
    maxWidth: 1440,
  },
  footerLink: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: '1.1rem',
  },
  footerBox: {
    margin: theme.spacing(0, 1),
  },
  titleLink: {
    textDecoration: 'none',
  },
  footerNavLink: {
    textDecoration: 'none',
    '&&:hover': {
      textDecoration: 'underline',
    },
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: theme.spacing(2, 0),
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
  SocialMediaIcons: {
    marginRight: theme.spacing(2),
  },
}))

const currentYear = () => {
  let d = new Date()
  return d.getFullYear()
}

export function Footer({darkMode}) {
  const classes = useStyles()
  const width = useSelector((state) => state.app.width)
  return (
    <Paper elevation={0} square className={classes.PaperRoot}>
      <CssBaseline />
      <Grid
        className={classes.root}
        container
        direction={width === 'xs' ? 'column' : 'row'}
        justify='space-between'
        alignItems='center'
      >
        <Grid item>
          <Container maxWidth={'lg'} className={classes.footerContainer}>
            <Typography component='span'>
              <Link
                variant='subtitle1'
                href='https://www.ra-micro.de/'
                target='_blank'
                rel='noopener'
                underline='hover'
                className={classes.link}
                color={darkMode ? 'inherit' : 'secondary'}
              >
                <CopyrightIcon style={{ width: '15px', marginRight: '5px' }} />{' '}
                RA-MICRO AG {currentYear()}
              </Link>
            </Typography>
            {width !== 'xs' && (
              <Typography component='span' className={classes.footerLink}>
                |
              </Typography>
            )}
            <Typography component='span'>
              <Link
                variant='subtitle1'
                href='https://wissenspool.ra-micro.de/kontakt/'
                target='_blank'
                rel='noopener'
                underline='hover'
                className={classes.link}
                color={darkMode ? 'inherit' : 'secondary'}
              >
                Kontakt
              </Link>
            </Typography>
            {width !== 'xs' && (
              <Typography component='span' className={classes.footerLink}>
                |
              </Typography>
            )}
            <Typography component='span'>
              <Link
                variant='subtitle1'
                href='https://www.ra-micro.de/datenschutz.html'
                target='_blank'
                rel='noopener'
                underline='hover'
                className={classes.link}
                color={darkMode ? 'inherit' : 'secondary'}
              >
                Datenschutz
              </Link>
            </Typography>
            {width !== 'xs' && (
              <Typography component='span' className={classes.footerLink}>
                |
              </Typography>
            )}
            <Typography component='span'>
              <Link
                href='https://www.ra-micro.de/info/impressum.html'
                target='_blank'
                rel='noopener'
                underline='hover'
                className={classes.link}
                color={darkMode ? 'inherit' : 'secondary'}
              >
                Impressum
              </Link>
            </Typography>
            {width !== 'xs' && (
              <Typography component='span' className={classes.footerLink}>
                |
              </Typography>
            )}
            <Typography component='span'>
              <Link
                href='https://www.ra-micro-online.de/'
                target='_blank'
                rel='noopener'
                underline='hover'
                className={classes.link}
                color={darkMode ? 'inherit' : 'secondary'}
              >
                RA-MICRO Store
              </Link>
            </Typography>
          </Container>
        </Grid>
        <Grid item className={classes.SocialMediaIcons}>
          <SocialMediaIcons />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Footer
