import { WIDTH, } from './types'

const initialState = {
    width: "xs"
}

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
      case WIDTH:
        return {...state, width: action.payload}

      default: return state
  }}