import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import logo from "../assets/img/ra-micro-logo.svg"
import { Grid, Paper } from "@material-ui/core"


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.type === "dark" ? '#616161': '',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
    minHeight: 20
  },
}))

export default function OutlinedCard({title, link, subtext, icon}) {
  const classes = useStyles()
  const theme = useTheme()
  const paperTheme = theme.palette.type
  
  return (
    <Paper
      variant='outlined'
      className={classes.root}
    >
      <Grid container alignItems='center' spacing={2} style={{flexGrow: 1}}>
        <Grid item>
          <img src={icon} alt={title} style={{ width: '3rem' }} />
        </Grid>
        <Grid item>
          <Typography
            className={classes.title}
            color='textSecondary'
            gutterBottom
          >
            <img
              src={logo}
              alt={`RA-MICRO – ${title}`}
              style={{ maxWidth: '5rem' }}
            />
          </Typography>
          <Typography variant='h5' component='h2'>
            {title}
          </Typography>
          <Typography className={classes.pos} color='textSecondary'>
            {subtext}
          </Typography>
        </Grid>
      </Grid>

      <Button
        size='large'
        color={paperTheme === 'dark' ? 'inherit' : 'primary'}
        href={link}
        target='_blank'
        variant='outlined'
        style={{ textTransform: 'none' }}
      >
        ansehen
      </Button>
    </Paper>
  )
}
