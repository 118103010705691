import React from 'react'
import { Paper, Grid, makeStyles } from "@material-ui/core"
import Card from './Card'
import ask from '../assets/icons/ask.svg'
import book from "../assets/icons/book.svg"
import lamp from "../assets/icons/lamp.svg"
import mediathek from "../assets/icons/mediathek.svg"
import prod from "../assets/icons/prod.svg"
import word from "../assets/icons/word.svg"
import info from "../assets/icons/info.svg"
import step from "../assets/icons/step.svg"

import { useSelector } from 'react-redux'

const tails = [
  {
    title: "Aktuelle Hinweise",
    link: "https://wissenspool.ra-micro.de/aktuelle-hinweise",
    icon: lamp,
    subtext: "Neues aus der Programmentwicklung",
  },
  {
    title: "Infopoint",
    link: "https://wissenspool.ra-micro.de/infopoint/",
    icon: info,
    subtext: " ",
  },
  {
    title: "Mediathek",
    link: "https://www.ra-micro.de/mediathek/",
    icon: mediathek,
    subtext: " ",
  },
  {
    title: "Erste Schritte",
    link: "https://www.ra-micro-doku.de/etv/archiv.pl?MedID=1&sel=31",
    icon: step,
    subtext: " ",
  },
  {
    title: "Handbücher",
    link: "https://wissenspool.ra-micro.de/handbuecher/",
    icon: book,
    subtext: " ",
  },
  {
    title: "Produktblätter",
    link: "https://wissenspool.ra-micro.de/produktblaetter/",
    icon: prod,
    subtext: " ",
  },
  {
    title: "Word Textverarbeitung",
    link:
      "https://wissenspool.ra-micro.de/themencode-pdf-viewer-sc/?tnc_pvfw=ZmlsZT0vd3AtY29udGVudC91cGxvYWRzL3Rhc3RlbmtvbWJpbmF0aW9uZW4ucGRmJnNldHRpbmdzPTExMTExMTExMTExMTExJmxhbmc9ZGU=#page=&zoom=page-fit&pagemode=",
    icon: word,
    subtext: " ",
  },
  {
    title: "Online Hilfen",
    link: "https://onlinehilfen.ra-micro.de/index.php/Hauptseite",
    icon: ask,
    subtext: " ",
  },
]

const useStyles = makeStyles((theme) => ({

  Paper: {
    maxWidth: 1440,
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(4),
    },
  },
  Box:{
    minHeight: '100%',
    minWidth: 300
  },
}))

export default function Content() {
    const width = useSelector((state) => state.app.width)
    const classes = useStyles()
    
    return (
      <Paper elevation={0} className={classes.Paper}>
        <Grid container spacing={4} alignItems='center' justify='center'>
          {tails.map((tail, i) => (
            <Grid
            item
              key={i}
              className={classes.Box}
              xs={
                ((i === 0 || i === 6) && width === 'lg' && 4) ||
                ((i === 0 || i === 6) && width === 'md' && 8) ||
                ((i === 0 || i === 7) && width === 'sm' && 12) ||
                (i !== 0 && width === 'sm' && 6) ||
                (width === 'xs' && 12)
              }
            >
              <Card
                title={tail.title}
                link={tail.link}
                icon={tail.icon}
                subtext={tail.subtext}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    )
}
