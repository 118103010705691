import React from 'react'
import {
  Link,
  Grid,
  Toolbar,
  AppBar,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core"
import RaMicroLogo from "../assets/img/ra-micro-logo.svg"
import { ToggleButton } from "@material-ui/lab"
import Brightness7Icon from "@material-ui/icons/Brightness7"
import Brightness4Icon from "@material-ui/icons/Brightness4"
import { useSelector } from "react-redux"

const useStyles = makeStyles((theme) => ({
  toolBar: {
    [theme.breakpoints.down("400")]: {
      alignItems: "center",
    },
    [theme.breakpoints.between("401", "600")]: {
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("1279")]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.up("1280")]: {
      flexWrap: "nowrap",
      justifyContent: "space-between",
    },
  },
  toolBarDarkModeBackground: {
    backgroundColor: "#616161",
  },
  toolBarRaMicroLogo: {
    marginTop: theme.spacing(1),
    height: 35,
  },
  toolBarRamTitle: {
    textAlign: "left",
    marginLeft: theme.spacing(1),
  },
  toolBarTitleLink: {
    color: "#333333",
    textTransform: "uppercase",
    flexGrow: 1,
    "&&:hover": {
      textDecoration: "none",
    },
  },
  ToggleButton: {
    border: "none",
    marginLeft: theme.spacing(1),
    "&&:hover": {
      backgroundColor: "transparent",
    },
    "&>span>svg": {
      fontSize: "1.2rem",
      color: "#444444",
    },
  },
  toolBarSettings: {
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      top: 0,
      right: 0,
    },
    [theme.breakpoints.down("760")]: {
      marginBottom: theme.spacing(1),
    },
  },
  InfolineDarkModeColor: {
    color: "white",
  },
  toolBarLogoWrap: {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("600px")]: {
    justifyContent: "center",
  },
  },
}))

export default function Header({setDarkMode, darkMode}) {
    const classes = useStyles()
    const width = useSelector(state => state.app.width)
    
    return (
      <AppBar position="static">
        <Toolbar
          className={
            darkMode ? classes.toolBarDarkModeBackground : classes.toolBar
          }
        >
          <Grid container className={classes.toolBarLogoWrap} spacing={2}>
            <Grid item xs="auto" sm={2} style={{ flexGrow: 1 }}>
              <Grid
                container
                justify={width === "xs" ? "center" : "flex-start"}
              >
                <Grid item>
                  <Typography
                    variant={width !== "xl" ? "h5" : "h4"}
                    className={classes.toolBarRamTitle}
                  >
                    <Link
                      href="https://www.ra-micro.de"
                      rel="noopener"
                      target="_blank"
                      className={classes.toolBarTitleLink}
                    >
                      <img
                        src={RaMicroLogo}
                        className={classes.toolBarRaMicroLogo}
                        alt="RA-MICRO"
                      />
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              className={
                darkMode ? classes.InfolineDarkModeColor : classes.Infoline
              }
            >
              <Typography component="p" variant="h3" align="center">
                RA-MICRO Wissen
              </Typography>
            </Grid>

            <Grid
              className={classes.toolBarSettings}
              xs="auto"
              sm={2}
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Tooltip title={darkMode ? "Helles Design" : "Dunkles Design"}>
                <ToggleButton
                  value="darkMode"
                  checked={darkMode}
                  disableRipple
                  onChange={() => setDarkMode(!darkMode)}
                  className={classes.ToggleButton}
                >
                  {!darkMode && <Brightness7Icon />}
                  {darkMode && <Brightness4Icon />}
                </ToggleButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    )
}
