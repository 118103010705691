import React from 'react'
import { Grid, IconButton, makeStyles } from "@material-ui/core"
import FacebookIcon from "@material-ui/icons/Facebook"
import TwitterIcon from "@material-ui/icons/Twitter"
import YouTubeIcon from "@material-ui/icons/YouTube"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import XingIcon from "../assets/icons/xing.svg"
import EmailIcon from "@material-ui/icons/Email"
import green from "@material-ui/core/colors/green"

const socialIcons = [
  {
    title: "Facebook",
    link: "https://www.facebook.com/ramicro.kanzleisoftware",
    icon: <FacebookIcon style={{ color: "#4267B2" }} />,
  },
  {
    title: "Twitter",
    link: "https://twitter.com/RAMICROSoftware",
    icon: <TwitterIcon style={{ color: "#1DA1F2" }} />,
  },
  {
    title: "YouTube",
    link: "https://www.youtube.com/channel/UCKSMVP1Na6A0KAKZsP7B0yA",
    icon: <YouTubeIcon style={{ color: "#FF0000" }} />,
  },
  {
    title: "Xing",
    link: "https://www.xing.com/companies/ra-microsoftwareag",
    icon: (
      <img
        src={XingIcon}
        alt="RA-MICRO bei Xing"
        style={{ width: "1.1rem"}}
      />
    ),
  },
  {
    title: "LinkedIn",
    link: "https://www.linkedin.com/company/ra-micro/",
    icon: <LinkedInIcon style={{ color: "#2867B2" }} />,
  },
  {
    title: "Email",
    link: "mailto:info@ra-micro.de",
    icon: <EmailIcon style={{ color: green[500] }} />,
  },
]

const useStyles = makeStyles((theme) => ({
  Icon: {
      padding: theme.spacing(1)
  }
}))

export default function SocialMediaIcons() {
    const classes = useStyles()

    return (
      <Grid container alignItems="center">
        {socialIcons.map((item, i) => (
          <IconButton key={i} href={item.link} className={classes.Icon}>{item.icon}</IconButton>
        ))
        }
      </Grid>
    )
}
